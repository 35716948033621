import { mapState, mapGetters } from "vuex";
import { isAdmin } from "@/auth/utils";

export default {
  computed: {
    ...mapState("verticalMenu", ["currentCooperative"]),
    ...mapState("cooperative", ["allFarms"]),
    ...mapState("admin", ["allCooperatives"]),
    ...mapGetters("admin", ["getCooperativeById"])
  },
  methods: {
    getAllCooperativeFarms() {
      if (isAdmin()) {
        if (this.allCooperatives) {
          this.$http.getCooperativeFarms({ cooperative_id: this.currentCooperative.id })
            .then(({ data }) => this.$store.commit("cooperative/SET_ALLFARMS", data))
            .catch(console.warn);
        } else {
          this.$http
            .ListCooperatives()
            .then(({ data }) => {
              this.$store.commit("admin/SET_ALLCOOPERATIVES", data);
            })
            .catch(console.warn);

          this.$http.getCooperativeFarms({ cooperative_id: this.$route.params.coop_id })
            .then(({ data }) => this.$store.commit("cooperative/SET_ALLFARMS", data))
            .catch(console.warn);
        }
      } else if (!this.allFarms) {
        this.$http.getCooperativeFarms({ cooperative_id: this.currentCooperative.id })
          .then(({ data }) => this.$store.commit("cooperative/SET_ALLFARMS", data))
          .catch(console.warn);
      }
    }
  }
};
