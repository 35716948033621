<template>
  <b-table
    responsive
    striped
    :fields="fields"
    :items="farmItems"
    class="m-0"
  >
    <template #table-colgroup="scope">
      <col
        v-for="field in scope.fields"
        :key="field.key"
        :class="{'button-col': field.key === 'voir' ||field.key === 'exploitants'}"
      >
    </template>
    <template #cell(cultures)="cultures">
      <b-badge
        v-for="culture in cultures.value"
        :key="culture"
        variant="light-primary"
        pill
        class="mr-1"
      >
        {{ localize(culture) }}
      </b-badge>
    </template>
    <template #cell(edit)="data">
      <b-button
        variant="flat-secondary"
        class="btn-icon rounded-circle"
        :to="{ name: 'coop-farm-detail', params: { id: data.item.id }}"
      >
        <feather-icon
          icon="UsersIcon"
          size="18"
        />
      </b-button>
    </template>
    <template #cell(preview)="data">
      <b-button
        variant="flat-secondary"
        class="btn-icon rounded-circle"
        :to="{ name: 'farm-home', params: { farm_id: data.item.id }}"
      >
        <feather-icon
          icon="EyeIcon"
          size="18"
        />
      </b-button>
    </template>
  </b-table>
</template>

<script>
import { BTable, BBadge, BButton } from "bootstrap-vue";
import localize from "@/utils/localize";

export default {
  components: {
    BTable,
    BBadge,
    BButton
  },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    farmItems: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    localize
  }
};
</script>

<style lang="scss" scoped>
  .button-col {
    width: 16%;
    text-align: center;
  }
</style>
