<template>
  <b-container v-if="farmItems">
    <b-row>
      <b-col cols="12">
        <h2 class="mb-2">
          Toutes les fermes de la coopérative
        </h2>
      </b-col>
      <b-col
        cols="12"
        style="background-color: #fff;"
        class="p-0 ml-1"
      >
        <FarmList
          :fields="fields"
          :farm-items="farmItems"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
import FarmList from "@/components/cooperative/FarmList.vue";
import farmListMixins from "@/mixins/farmList";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    FarmList
  },
  mixins: [farmListMixins],
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Ferme"
        },
        "cultures",
        {
          key: "edit",
          label: "exploitants",
          class: "text-center"
        },
        {
          key: "preview",
          label: "voir la ferme",
          class: "text-center"
        }
      ],
      currentFarms: []
    };
  },
  computed: {
    farmItems() {
      const items = [];
      const farms = this.isNotFiltered ? this.allFarms : this.currentFarms;
      farms.forEach(farm => {
        const cultures = [
          ...new Set([
            // TODO get rid of this mapping when we API is ready
            ...farm.hve_cultures.map(c => (c === "cereal" ? "ble" : c)),
            ...farm.ges_cultures
          ])
        ];
        items.push({ name: farm.name, cultures, id: farm.id });
      });

      return items;
    },
    isNotFiltered() {
      return !this.$route.query.scope && this.allFarms;
    }
  },
  created() {
    this.getCooperativeFarms();
  },
  methods: {
    getCooperativeFarms() {
      if (this.$route.query.scope) {
        this.$http.getCooperativeFarms({ cooperative_id: this.currentCooperative.id, scope: this.$route.query.scope })
          .then(({ data }) => {
            this.currentFarms = data;
          })
          .catch(console.warn);
      } else {
        this.getAllCooperativeFarms();
      }
    }
  }
};
</script>
